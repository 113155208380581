.container {
	position: relative;
	height: 100%;
	width: 100%;
}

.close {
	position: absolute;
	top: 1rem;
	right: 1rem;
	height: 56px;
	width: 56px;
	background-color: white;
	border-radius: 100%;
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.close svg {
	height: 24px;
	width: 24px;
	pointer-events: none;
}

.result {
	position: absolute;
	top: 1rem;
	left: 1rem;
	display: inline-block;
	padding: 0.25rem;
	background-color: white;
	color: black;
	z-index: 100;
}

.reader {
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.reader div {
	padding: 0 !important;
	height: 100%;
}

.reader video {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.overlay {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 100px;
	background-color: rgba(0, 0, 0, 0.8);
	display: flex;
	align-items: center;
	justify-content: center;
}

.overlay span {
	display: block;
	height: 60px;
	width: 60px;
	background-color: red;
	border-radius: 100%;
	border: 2px solid white;
	cursor: pointer;
}


.output {
	background: white;
	color: black;
	text-align: center;
	padding: 10px;
}

.output h1 {
	padding: 20px;
}

.success {
	background: green;
	color: white;
}
.error {
	background: red;
	color: white;
}

.btnAddPeople {
	background: black;
	color: white;
	width: 100px;
	height: 50px;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	border-radius: 10px;
}

.additionalButtonWrapper {
	vertical-align: center;
}

.container {
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: black;
	height: 100vh;
}
.button {
	border: none;
	cursor: pointer;
}
.logo {
	background-color: transparent;
	transform: scale(1);
	transition: transform 0.2s ease-in-out;
	animation: rotate 1s infinite;
}

.button:hover img {
	transform: scale(1.1);
	transition: transform 0.2s ease-in-out;
}

.btnScan {
	background: green;
	color: white;
	width: 200px;
	height: 50px;
	cursor: pointer;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	margin: 4px 2px;
	border-radius: 10px;
}

.output {
	width: 80%;
}
.center {
	text-align: center;
}

.version {
	color: white;
	text-align: center;
}

@keyframes rotate {
	0% {
		transform: rotate(-10deg);
	}
	50% {
		transform: rotate(10deg);
	}
	100% {
		transform: rotate(-10deg);
	}
}
